<template>
    <div>
        <h3>Edit Blog</h3>
        <v-btn
                text
                :to="{ name: 'manage-blogs', params: { domainname: siteUrl }}"
                outlined
                class="btn btn-lg btn-secondary-main"
                style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <v-app>
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col>Edit Blogs</v-col>
                        <v-col>
                            <div class="text-right">
                                <!-- <v-btn class="ma-2" outlined raised color="indigo">SEO</v-btn> -->

                                <!-- <v-btn class="ma-2" @click="openSeoDialog" x-small fab title="Seo Settings">
                                  <v-icon>flaticon-diagram</v-icon>
                                </v-btn>
                                <v-btn class="ma-2" @click="openSettingDialog" x-small fab title="Setting">
                                  <v-icon>flaticon2-gear</v-icon>
                                </v-btn>-->
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="8">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field label="Title" v-model="blog.title" required outlined
                                                  dense></v-text-field>
                                    <p
                                            class="text-danger font-weight-bold"
                                            v-if="$v.blog.title.$error"
                                    >Title is required</p>
                                </v-col>
                                <v-col cols>
                                    <ckeditor v-model="blog.content"></ckeditor>
                                </v-col>
                            </v-row>



                        </v-col>
                        <v-col cols="4">
                            <v-switch label="SEO" v-model="showSeo">

                            </v-switch>
                            <div v-if="showSeo">
                                <span class="headline">SEO</span>
                                <br/>

                                <v-text-field outlined dense label="Title for SEO" v-model="blog.seo_title"
                                              required></v-text-field>
                                <v-textarea
                                        outlined
                                        dense
                                        label=" Description for SEO"
                                        v-model="blog.seo_description"
                                        required
                                ></v-textarea>
                            </div>
                            <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                    outlined
                                    dense
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="blog.publish_date"
                                            label="Published Date"
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                            outlined
                                            class="mt-2"
                                            dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="blog.publish_date"
                                               @input="menu2 = false"></v-date-picker>
                            </v-menu>
                            <v-col class="mt-2" cols="12" sm="12"><label for="">Blog Category</label>
                                <v-btn
                                        text
                                        @click="showDialog"
                                        outlined
                                        depressed
                                        class="btn btn-lg btn-add-main text-right float-right mb-2"
                                        style="background:transparent;"
                                >
                                    <i class="fas fa-plus-circle"></i> Add New Category
                                </v-btn>
                                <treeselect
                                        :multiple="true"
                                        :options="categories"
                                        v-model="category_value"
                                        :sort-value-by="sortValueBy"
                                        placeholder="Choose catalogs"
                                        :disable-branch-nodes="false"
                                        :show-count="true"
                                        search-nested
                                        :flatten-search-results="true"
                                />

                            </v-col>
                            <div class="row mt-5">
                                <v-file-input
                                        :rules="rules"
                                        outlined
                                        dense
                                        accept="image/png, image/jpeg, image/bmp"
                                        placeholder="Pick an Featured Image"
                                        prepend-innner-icon="mdi-camera"
                                        persistent-hint
                                        @change="imageChange"
                                        hint
                                        label="Featured Image"
                                ></v-file-input>

                            </div>

                            <v-col cols="12">
                                <div class="preview">
                                    <img v-if="url" :src="url"/>
                                </div>
                            </v-col>
                            <br>
                            <v-text-field label="Author" v-model="blog.author" dense outlined>
                            </v-text-field>
                            <v-file-input
                                    :rules="rules"
                                    outlined
                                    dense
                                    accept="image/png, image/jpeg, image/bmp"
                                    placeholder="Pick an Featured Image"
                                    prepend-inner-icon="mdi-camera"
                                    prepend-icon=""
                                    persistent-hint
                                    @change="imageAuthorChange"
                                    label="Author Image"
                            ></v-file-input>
                            <v-col cols="12">
                                <div class="preview">
                                    <img v-if="authorPreviewurl" :src="authorPreviewurl" />
                                </div>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                            class="kt-link"
                            color="blue darken-1"
                            text
                            :to="{ name: 'manage-blogs', params: { domainname: siteUrl }}"
                    >Cancel
                    </v-btn>
                    <v-btn
                            @click="saveBlog"
                            :loading="isBusy"
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                    >Save
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-dialog max-width="600"  scrollable v-model="add_category_dialog">
                <v-card>
                    <v-card-title>
                        <span class="headline">Add Blog Category</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <div class="form-block">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                                label="Title"
                                                v-model="blogCategory.title"
                                                required
                                                outlined
                                                densed
                                        ></v-text-field>
                                        <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.blogCategory.title.$error"
                                        >Title is required</p>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-select
                                                v-model="blogCategory.layout"
                                                :items="layoutList"
                                                item-text="name"
                                                item-value="value"
                                                label="Select Layout"
                                                outlined
                                                densed
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="!blogCategory.is_parent">
                                        <cascader
                                                style="width:100%;"
                                                size="large"
                                                :options="categories"
                                                v-model="selected"
                                                :fieldNames="{label: 'title', value: 'id', children: 'children'}"
                                                placeholder="Select parent category"
                                                change-on-select
                                        />
                                    </v-col>
                                    <!-- <pre>{{blogCategorys}}</pre> -->
                                    <!-- <v-col cols="12" md="12">
                                      <v-select
                                        v-model="blogCategory.parent_id"
                                        :items="blogCategorys"
                                        item-text="title"
                                        item-value="id"
                                        label="Select Parent Category"
                                        outlined
                                        densed
                                      ></v-select>
                                    </v-col>-->
                                    <!-- <pre>{{selected}}</pre> -->
                                    <v-col cols="12" md="12">
                                        <v-switch v-model="blogCategory.is_parent" label="Top Level"></v-switch>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-switch v-model="blogCategory.is_active" label="Visibility"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row></v-row>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="add_category_dialog=false">Close</v-btn>
                        <v-btn
                                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                class="btn-save-popup"
                                :loading="isBusy"
                                text
                                @click.prevent="saveOrUpdate"
                        >Save
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </v-app>
        <pre>
      </pre>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import CKEditor from "ckeditor4-vue";
    import WebsiteBlogCategory from "@/services/Websites/WebsiteBlogCategoryService";
    import WebsiteBlog from "@/services/Websites/WebsiteBlogService";

    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import Cascader from "ant-design-vue/lib/cascader";
    import "ant-design-vue/dist/antd.css";

    const WebsiteBlogService = new WebsiteBlog();
    const WebsiteBlogCategoryService = new WebsiteBlogCategory();

    export default {
        name: "websites-edit-blog",
        components: {
            KTPortlet,
            Cascader,
            treeselect: Treeselect,
            ckeditor: CKEditor.component
        },
        validations: {
            blog: {
                title: {required},
                slug: {required}
            },
            blogCategory: {
                title: {required}
            }
        },

        data() {
            return {
                blogCategory: null,
                add_category_dialog: false,

                category_value: [],
                valueConsistsOf: "ALL_WITH_INDETERMINATE",
                value: null,
                sortValueBy: "ORDER_SELECTED",
                options: [],
                isBusy: false,
                showSeo: false,
                layoutList: [
                    {
                        name: "Standard",
                        value: "standard"
                    },
                    {
                        name: "FAQ",
                        value: "faq"
                    }
                ],
                fields: [
                    {key: "title", sortable: true},
                    {key: "is_active", label: "Published", sortable: true},
                    {key: "action"}
                ],
                rules: [
                    value =>
                        !value ||
                        value.size < 2000000 ||
                        "Avatar size should be less than 2 MB!"
                ],
                featureImageChange: 0,
                featureAuthorImageChange: 0,
                moreOption: 0,
                url: null,
                authorPreviewurl: null,
                featuredImageDialog: false,
                seoDialog: false,
                settingDialog: false,
                menu2: false,
                editCategoryIds: [],
                blog: {
                    title: "",
                    slug: "",
                    publish_date: "",
                    category_id: null,
                    seo_title: "",
                    seo_description: "",
                    social_share_image: "",
                    content: "Content Here"
                },
                blogCategory: {
                    id: null,
                    title: "",
                    slug: "",
                    layout: null,
                    is_parent: 0,
                    is_active: 1,
                    parent_id: null
                },
                categories: [],
                selected: [],
                values: [],
                value: null
            };
        },
        methods: {
            showDialog() {
                this.add_category_dialog = true;
            },
            openSettingDialog() {
                this.settingDialog = true;
            },
            closeSettingDialog() {
                this.settingDialog = false;
            },
            openSeoDialog() {
                this.seoDialog = true;
            },
            closeDialog() {
                this.add_category_dialog = false;
            },
            openFeaturedImageDialog() {
                this.featuredImageDialog = true;
            },
            closeFeaturedImageDialog() {
                this.featuredImageDialog = false;
            },
            imageChange(e) {
                this.url = URL.createObjectURL(e);
                this.blog.social_share_image = e;
                this.featureImageChange++;
            },
            imageAuthorChange(e) {
                this.authorPreviewurl = URL.createObjectURL(e);
                this.blog.author_image = e;
                this.featureAuthorImageChange++;
            },
            getBlogCategory() {
                WebsiteBlogCategoryService.paginate(this.siteUrl).then(res => {
                    this.categories = res.data.data;
                    // this.pages = res.data.data;
                });
            },
            getBlog() {
                WebsiteBlogService.show(this.siteUrl, this.blogId).then(res => {
                    this.blog = res.data.blog;
                    this.url = this.blog.social_share_image_path.real_path;
                    this.authorPreviewurl = this.blog.author_image_path.real_path;
                    this.category_value = this.blog.category_id.split(",").map(Number);
                    // this.pages = res.data.data;
                });
            },

            // save or update blog categories
            saveOrUpdate() {
                this.$v.$touch();
                if (!this.$v.blogCategory.$error) {
                    if (this.selected.length > 0)
                        this.blogCategory.parent_id = this.selected[this.selected.length - 1];
                    this.isEdit ? this.updateBlogCategory() : this.saveblogCategory();
                }
            },
            updateBlogCategory() {
                this.isBusy = true;
                WebsiteBlogCategoryService.update(
                    this.siteUrl,
                    this.blogCategory.id,
                    this.blogCategory
                )
                    .then(res => {
                        this.isBusy = false;
                        this.$snotify.success("category updated ");

                        this.getBlogCategory();
                        this.closeDialog();
                    })
                    .catch(error => {
                    })
                    .finally(() => (this.isBusy = false));
            },
            saveblogCategory() {
                this.isBusy = true;
                WebsiteBlogCategoryService.create(this.siteUrl, this.blogCategory)
                    .then(res => {
                        this.isBusy = false;
                        this.$snotify.success("category  added");
                        this.getBlogCategory();
                        this.closeDialog();
                    })
                    .catch(error => {
                    })
                    .finally(() => (this.isBusy = false));
            },

            saveBlog() {
                this.blog.category_id = this.category_value;
                let fd = new FormData();
                fd.append("title", this.blog.title);
                fd.append("category_id", this.blog.category_id);
                fd.append("seo_title", (this.blog.seo_title) ? this.blog.seo_title : '');
                fd.append("content", (this.blog.content) ? this.blog.content : "");
                fd.append("author", this.blog.author);
                fd.append("seo_description", (this.blog.seo_description) ? this.blog.seo_description : '');
                fd.append("publish_date", this.blog.publish_date);
                if (this.featureImageChange > 0) {
                    fd.append(
                        "social_share_image",
                        this.blog.social_share_image,
                        this.blog.social_share_image.name
                    );
                }
                if (this.featureAuthorImageChange > 0) {
                    fd.append(
                        "author_image",
                        this.blog.author_image,
                        this.blog.author_image.name
                    );
                }
                this.isBusy = true;
                WebsiteBlogService.update(this.siteUrl, this.blogId, fd)
                    .then(res => {
                        this.isBusy = false;
                        this.$snotify.success(" Blogs Update");
                        this.$router.push({
                            name: "manage-blogs",
                            params: {domainname: this.siteUrl}
                        });
                    })
                    .catch(err => {
                    })
                    .finally(() => (this.isBusy = false));
            }
        },
        computed: {
            siteUrl() {
                return this.$route.params.domainname;
            },
            blogId() {
                return this.$route.params.id;
            }
        },
        mounted() {
            this.getBlog();
            this.getBlogCategory();
            this.blog.author = this.currentUser.full_name;
        }
    };
</script>

<style scoped>
    .preview {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .preview img {
        max-width: 100%;
        max-height: 350px;
    }
</style>
